
import ModalPopup from '~/components/app/modal-popup'
import EmailLink from '~/components/app/modal/EmailLink'

export default {
  name: 'login',
  components: { EmailLink, ModalPopup },
  middleware: ['not-authenticated'],
  data() {
    return {
      stage: 1,
      email: '',
      password: '',
      showModal: false,
      modalTitle: '',
      modalBody: '',
    }
  },
  head() {
    return {
      htmlAttrs: {
        lang: 'en',
      },
      title: 'EchoWave - Login',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: 'Login to your EchoWave account via email.',
        },
      ],
    }
  },
  methods: {
    async login() {
      // try {
      //   await this.$fire.auth.signInWithEmailAndPassword(this.email, this.password).then(() => {
      //     this.$router.push('/app/')
      //   })
      // } catch (e) {
      //   console.error('>> Login error', e)
      // }

      const actionCodeSettings = {
        // URL you want to redirect back to. The domain (www.example.com) for this
        // URL must be in the authorized domains list in the Firebase Console.
        url: `${window.location.origin}/app/finish-login/`,
        // This must be true.
        handleCodeInApp: true,
      }

      this.$fire.auth
        .sendSignInLinkToEmail(this.email, actionCodeSettings)
        .then(() => {
          if (typeof $crisp !== 'undefined') {
            $crisp.push(['set', 'session:event', [[['send_sign_in_link_to_email', { email: this.email }, 'green']]]])
          }

          if (typeof posthog !== 'undefined') {
            posthog.capture('send_sign_in_link_to_email', { email: this.email })
          }
          // The link was successfully sent. Inform the user.
          // Save the email locally so you don't need to ask the user for it again
          // if they open the link on the same device.
          window.localStorage.setItem('emailForSignIn', this.email)
          this.stage = 2
        })
        .catch((error) => {
          if (typeof $crisp !== 'undefined') {
            $crisp.push(['set', 'session:event', [[['login_error', { error, email: this.email }, 'orange']]]])
          }

          if (typeof posthog !== 'undefined') {
            posthog.capture('login_error')
          }
          this.showModal = true
          this.modalTitle = 'Error'
          this.modalBody = error
        })
    },
  },
}
